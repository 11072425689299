import {createTheme} from "@mui/material";

export const edisonTheme = createTheme({
    components: {
        MuiOutlinedInput:{
            styleOverrides:{
                root:{
                    borderColor:'#E94E0F',
                    borderRadius: 0
                },
                notchedOutline:{
                    borderColor:'#E94E0F',
                }
            }
        },
        MuiInputBase:{
            styleOverrides:{
                root:{
                    borderColor:'#E94E0F',
                    borderRadius: 0
                }
            }
        }
    },
    palette: {
        mode: "dark",
        primary: {
            main: '#E94E0F'
        },
        divider: '#E94E0F'
    }
})
